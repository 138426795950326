import React from 'react'
import Qr from '../qrcode/Qr'
import './Home.css'

function Home() {
  return (
    <div className='home'>
        <Qr />
    </div>
  )
}

export default Home